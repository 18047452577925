import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import Layout from "../components/common/Layout";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import CenteredSection from "../components/common/CenteredSection";
import { getImage } from "gatsby-plugin-image";
import ContactUs from "../components/common/ContactUs";
import TabSection from "../components/common/TabSection";
import Seo from "../components/default/seo";
import BenchmarkPlanningIcon from "../images/icons/benchmark_planning.svg";
import DataAcquisitionIcon from "../images/icons/data_acquisition.svg";
import IntegrationIcon from "../images/icons/integration.svg";
import MethodsPlanningIcon from "../images/icons/methods_planning.svg";
import BenchmarkPlanningSelectedIcon from "../images/icons/benchmark_planning_selected.svg";
import DataAcquisitionSelectedIcon from "../images/icons/data_acquisition_selected.svg";
import IntegrationSelectedIcon from "../images/icons/integration_selected.svg";
import MethodsPlanningSelectedIcon from "../images/icons/methods_planning_selected.svg";

const tabs = [
  {
    title: "saasPlatformTitle",
    icon: BenchmarkPlanningIcon,
    iconSelected: BenchmarkPlanningSelectedIcon,
    img: "platform",
    text: "saasPlatformText",
  },
  {
    title: "dataTitle",
    icon: DataAcquisitionIcon,
    iconSelected: DataAcquisitionSelectedIcon,
    img: "data",
    text: "dataText",
  },
  {
    title: "contentLibraryTitle",
    icon: MethodsPlanningIcon,
    iconSelected: MethodsPlanningSelectedIcon,
    img: "library",
    text: "contentLibraryText",
  },
  {
    title: "integrationTitle",
    icon: IntegrationIcon,
    iconSelected: IntegrationSelectedIcon,
    img: "integration",
    text: "integrationText",
  },
];

export default function Solutions({ data }) {
  const [images, setImages] = useState({});
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const images = {};
    for (const edge of data.images.edges) {
      images[edge.node.name] = getImage(edge.node);
    }
    setImages(images);
  }, [data.images]);

  return (
    <Layout>
      <Seo
        title={t("servicesHeaderTitle")}
        description={t("servicesHeaderText")}
        lang={language}
      />
      <PageHeader
        title={t("servicesHeaderTitle")}
        text={t("servicesHeaderText")}
        img={images.hero}
      />
      <CenteredSection title={t("servicesIntroTitle")}>
        <p>{t("servicesIntroText")}</p>
      </CenteredSection>
      <TabSection tabs={tabs} images={images} />
      <ContactUs />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "services"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "services" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
